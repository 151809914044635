<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{title}}
        <v-spacer></v-spacer>
        <v-btn color="warning" dark small @click="$router.back()">
          <v-icon small dark>mdi-arrow-left</v-icon>返回
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <v-row align-content='start'>
          <v-col>
            <v-text-field v-model="username" label="用户名"></v-text-field>
            <v-select :items="keyArr" v-model="keyId" dense label="密钥"></v-select>
            <v-btn color="primary" class="mt-2" style="margin-right:10px;" small @click="init">{{wsAction}}</v-btn>
            <v-btn class="mt-2" color="info" small @click="install" :disabled="(keyId!=null&&websock!=null&&username)?false:true">
              {{type}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-tabs dark background-color="primary" show-arrows>
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
          <v-tab v-for="(item,index) in ips" :key="index" :href="'#tab-' + index">
            <v-icon small left>{{item.icon}}</v-icon>{{item.ip}}
          </v-tab>
          <v-tab-item v-for="(item,index) in ips" :key="index" :value="'tab-' + index" eager>
            <div ref="myby" :id="'myby'+index" style="height:450px;background-color:#000000;color:#ffffff;overflow:auto;padding:10px;white-space: pre;">
              {{item.message}}
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  import { post, tips, domain, getCookie } from '@/facade';
  export default {
    name: 'test',
    data() {
      return {
        websock: null,
        keyArr: [],
        keyId: null,
        keys: [],
        actions: 'INSTALL_SERVER',
        ips: [],
        type: '安装',
        temper: false,
        title: '一键安装',
        username: 'root',
        wsAction: '连接ws',
        keyType: '',
        linkIds: []
      }
    },
    async created() {
      this.type = this.$route.query.type === 'install' ? '安装' : "卸载";
      this.title = this.$route.query.type === 'install' ? '一键安装' : "一键卸载";
      this.actions = this.$route.query.type === 'install' ? 'INSTALL_SERVER' : "UNINSTALL_SERVER";
      const arr = JSON.parse(this.$route.query.items)
      this.ips = arr.length > 0 ? arr.map(item => ({ ip: item.hosts, id: item.id, message: '', icon: '', num: 0 })) : []
      await this.getKeys()
    },
    async beforeRouteLeave(to, from, next) {
      if (this.websock) {
        const value = await confirm('安装过程中离开,服务器会出现未知问题，请慎重选择')
        if (value) {
          this.websock.close(); //离开路由之后断开websocket连接
          this.websock = null;
          next()
        }
      } else {
        next()
      }
    },
    mounted() {
      window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    },
    methods: {
      // eslint-disable-next-line no-unused-vars
      beforeunloadFn(e) {
        if (this.websock) {
          this.websock.close();
          this.websock = null;
          this.wsAction = '连接ws'
        }
      },
      async getKeys() {
        const { code, password_list, message } = await post('/vnet/credential/list')
        if (code === 'ok') {
          this.loading = false;
          this.keys = password_list;
          this.keyArr = password_list.map(item => ({ text: (item.name || item.username) + `(${item.type==='password'?'密码':'密钥'})`, value: item.id }))
        } else tips('danger', message)
      },
      initWebSocket() { //初始化weosocket
        const start = document.location.protocol == 'https:' ? "wss://" : "ws://"
        const wsuri = start + domain + "/vnet/ws/" + getCookie('uid');
        this.websock = new WebSocket(wsuri);
        this.websock.onopen = () => {
          tips('success', 'websocket连接成功')
          this.wsAction = '断开ws'
        }
        this.websock.onmessage = (e) => {
          const resData = JSON.parse(e.data);
          if (resData.ip) {
            const index = this.ips.findIndex(item => item.id == resData.id);
            if (this.type == '安装') {
              if (resData.data.indexOf('forward_status_success') != -1 || resData.data.indexOf('server_status_success') != -1) {
                this.ips[index].num += 1;
                const ipLength = this.ips[index].ip.split('-').length
                if (this.ips[index].num == ipLength) {
                  this.ips[index].icon = 'mdi-checkbox-marked-circle'
                  this.temper = true
                } else {
                  if (!this.temper) this.ips[index].icon = 'mdi-alert-circle'
                }
              }
            } else {
              if (resData.data.indexOf('running server NOW,STOP IT')!=-1) {
                this.ips[index].icon = 'mdi-checkbox-marked-circle'
                this.temper = true
              } else {
                if (!this.temper) this.ips[index].icon = 'mdi-alert-circle'
              }
            }
            var divscll = document.getElementById('myby' + index)
            divscll.scrollTop = divscll.scrollHeight;
            this.ips[index].message+=resData.ip+' -- '+resData.data+'\n';
          }
        }
        this.websock.onerror = () => {
          this.websock = null;
          this.initWebSocket();
        }
        // eslint-disable-next-line no-unused-vars
        this.websock.onclose = (e) => {
          tips('error', 'ws已断开')
          this.websock = null;
          this.wsAction = '连接ws'
        }
      },
      /**连接ws */
      init() {
        if (this.websock) this.websock.close()
        else this.initWebSocket()
      },
      /**安装或卸载 */
      install() {
        if (this.websock) {
          this.domain = false
          this.ips.forEach(element => {
            element.num = 0
            element.message=''
          });
          console.log(this.ips)
          const linkIds = this.ips.map(item => item.id)
          const info = {
            action: this.actions,
            data: { link_ids: linkIds, pid: this.keyId, username: this.username }
          }
          this.websock.send(JSON.stringify(info))
        }
      },
    },
  }
</script>